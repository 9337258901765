import React from 'react';
import Link from 'next/link';
import Icon from './icon/Icon';
import Date from './date';

import { Text, Muted } from './text';

interface IProps {
	title: string;
	videoLink: string;
	alt: string;
	imageUrl: string;
	className?: string;
	bgColor?: 'solid' | 'transparent';
	duration?: string;
	size?: 'small' | 'medium' | 'large';
	date?: string;
}

export function VideoCard({
	title,
	videoLink,
	alt,
	imageUrl,
	className,
	duration,
	bgColor = 'transparent',
	size = 'small',
	date,
	...props
}: IProps) {
	let cardClassName = className || '';

	return (
		<div
			className={`rounded-md flex ${
				bgColor === 'transparent' ? 'bg-transparent' : 'bg-white'
			} ${cardClassName} card card-${size}`}
			{...props}
		>
			<div className="p-0 m-0 relative flex-none w-1/3 items-center lg:w-full">
				<Link href={videoLink}>
					<img
						src={imageUrl}
						alt={alt}
						className="aspect-video h-full w-full object-contain md:object-initial"
					/>
				</Link>
				{duration && (
					<div className="absolute bottom-0 left-0 p-4 items-center hidden sm:flex">
						<div className="bg-red-500 p-3 rounded-s-md inline-block">
							<Icon icon="play" size={12} color={'white'} />
						</div>
						<div className="flex-none min-h-[36px] w-14 inline-block rounded-e-md bg-black-500 text-center">
							<Text className="font-inter text-white mt-0.5 text-sm inline-block m-0 p-2 font-bold leading-none">
								{duration}
							</Text>
						</div>
					</div>
				)}
			</div>
			<div className="card-content flex-1">
				<Link href={videoLink}>
					<Text className="card-title hover:underline">{title}</Text>
				</Link>
				{size !== 'small' && date && (
					<Muted className="card-date mb-3 hidden lg:block">
						<Date dateString={date} />
					</Muted>
				)}
			</div>
		</div>
	);
}
