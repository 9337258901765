import React from 'react';
import Date from './date';
import Link from 'next/link';
import { Text, Muted } from './text';

interface IProps {
	title: string;
	date: string;
	slug: string;
	children?: React.ReactNode;
	className?: string;
	snug?: boolean;
}

const icon = (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#E2013D" className="w-6 h-6">
		<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
	</svg>
)

export default function PostListItem({
	title,
	date,
	slug,
	className,
	snug,
}: IProps) {
	const cardClassName = className || '';

	return (
		<>
			{!snug && (
				<li className={`block md:flex justify-between gap-x-6 py-3 ${cardClassName}`}>
					<div className="flex gap-x-4 items-center md:w-3/4">
						<div>
						{icon}
						</div>
						<div className="min-w-0 flex-auto">
							<Text className="leading-relaxed font-bold text-base">
								<Link
									href={`/nieuws/${slug}`}
									className="hover:underline"
									dangerouslySetInnerHTML={{ __html: title }}
								></Link>
							</Text>
						</div>
					</div>
					<div className="hidden md:flex items-center">
						<Muted className="text-base leading-relaxed">
							<Date dateString={date} />
						</Muted>
					</div>
				</li>
			)}
			{snug && (
				<li className={`block md:flex py-3 ${cardClassName}`}>
					<div className="flex gap-x-2 items-start md:w-full">
						<div className="p-1">
							{icon}
						</div>
						<div className="min-w-0 flex-auto">
							<Text className="leading-relaxed font-bold text-sm block mb-1">
								<Link
									href={`/nieuws/${slug}`}
									className="hover:underline"
									dangerouslySetInnerHTML={{ __html: title }}
								></Link>
							</Text>
							<div className="md:flex items-center">
								<Muted className="text-base leading-relaxed text-sm">
									<Date dateString={date} />
								</Muted>
							</div>
						</div>
					</div>
				</li>
			)}
		</>
	);
}

