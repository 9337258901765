import React from 'react';
import { classNames } from '../../utils/applyCssClassNames';

interface IProps {
	children?: React.ReactNode;
	className?: string;
	nowrap?: boolean;
}

export function TableCell({children, className, nowrap = false}: IProps) {
	let tableClassName = className || '';

	return (
		<td className={classNames(
			nowrap
				? 'whitespace-nowrap'
				: 'whitespace-normal',
			`text-left uppercase ${tableClassName}`,
		)}>{children}</td>
	);
}

