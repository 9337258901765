import React from 'react';

interface IProps {
	children?: React.ReactNode;
	className?: string;
}

export function TableHeadRow({children, className}: IProps) {
	let classNames = className || '';

	return (
		<tr className={` ${classNames}`}>{children}</tr>
	);
}

