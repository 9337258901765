import React from 'react';

interface IProps {
	children?: React.ReactNode;
	className?: string;
}

export function TableBody({children, className}: IProps) {
	let classNames = className || '';
	return (
		<tbody className={`${classNames}`}>{children}</tbody>
	);
}

