import React from 'react';
import Link from 'next/link';
import Swiper from 'swiper';
import IconMore from './icon/Icon-more';

import { IHeroPost } from '../models/hero-slider';

interface IProps {
	swiperRef: React.MutableRefObject<Swiper | null>;
	post: IHeroPost;
}

export default function HeroPostPreview({ swiperRef, post }: IProps) {
	return (
		<>
			<div className="swiper-preview">
				<img
					src={post.postObject.featuredImage?.node.sourceUrl}
					alt={post.postObject.featuredImage?.node.altText}
				/>
				<div className="slide-content">
					<div className="text-white theinhardt">{post.postObject.title}</div>
				</div>
			</div>

			<div className="skip-to-preview text-white" onClick={() => swiperRef.current?.slideNext()}>
				<Link href="/#" aria-label="Naar het artikel">
					<IconMore />
				</Link>
			</div>
		</>
	);
}
