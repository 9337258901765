import React from 'react';
import { Label } from './text';

interface IProps {
	children: React.ReactNode;
	className?: string;
}

export function Badge({ className, children, ...props }: IProps) {
	return (
		<span
			className={`bg-red-500 py-1 px-2 inline-block rounded-md ${className}`}
			{...props}
		>
			<Label className="text-white leading-none text-sm lg:text-base">
				{children}
			</Label>
		</span>
	);
}
