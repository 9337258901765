import React from 'react';

interface IProps {
	children?: React.ReactNode;
	className?: string;
}

export function TableHeadCell({children, className}: IProps) {
	let classNames = className || '';

	return (
		<th scope="col" className={`text-left uppercase font-bold ${classNames}`}>{children}</th>
	);
}

