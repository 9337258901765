import React from 'react';
import { classNames } from '../../utils/applyCssClassNames';

interface IProps {
	children?: React.ReactNode;
	className?: string;
	striped?: boolean;
}

export function TableRow({children, className, striped = true}: IProps) {
	let rowClassName = className || '';

	return (
		<tr className={classNames(
			striped
				? 'even:bg-gray-50'
				: 'bg-transparent',
			`${rowClassName}`,
		)}>
			{children}
		</tr>
	);
}

