import React from 'react';
import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { IFeaturedImage } from '../models/featured-image';

export interface ICoverImageprops {
	title: string;
	coverImage: IFeaturedImage;
	slug?: string;
	className?: string;
}

export default function CoverImage({
	title,
	coverImage,
	slug,
	className,
}: ICoverImageprops) {
	const image = (
		<Image
			width={1}
			height={1}
			layout="responsive"
			alt={`Cover Image for ${title}`}
			src={coverImage?.node.sourceUrl}
			className={cn(`shadow-small object-cover ${className}`, {
				'hover:shadow-medium transition-shadow duration-200': slug,
			})}
		/>
	);
	return (
		// Todo DEVP-142 -  remove overflow hidden
		<div className="sm:mx-0">
			{slug ? (
				<Link href={`/nieuws/${slug}`} aria-label={title}>
					{image}
				</Link>
			) : (
				image
			)}
		</div>
	);
}
