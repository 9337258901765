import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import HeroPostContent from './hero-post-content';
import HeroPostSlide from './hero-post-slide';
import HeroPostPreview from './hero-post-preview';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { IHeroPost } from '../models/hero-slider';

interface IProps {
	data: IHeroPost[];
}

export default function HeroPost({ data }: IProps) {
	const progressStrokeRef = useRef<HTMLDivElement | null>(null);
	const swiperRef = useRef<Swiper | null>(null);

	useEffect(() => {
		swiperRef.current = new Swiper('.front-page-swiper', {
			modules: [Autoplay, Navigation, Pagination],
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			on: {
				autoplayTimeLeft(_, __, progress) {
					if (progressStrokeRef.current) {
						progressStrokeRef.current.style.width = `${(1 - progress) * 100}%`;
					}
				},
				slideChange(swiper) {
					const activeIndex = swiper.activeIndex;
					const nextIndex = (activeIndex + 1) % swiper.slides.length;

					const contentRef = document.querySelector('.swiper-content');
					const previewRef = document.querySelector('.swiper-preview');

					if (contentRef) {
						const activeContent = swiper.slides[activeIndex].querySelector('.slide-content')?.innerHTML;
						if (activeContent) {
							contentRef.innerHTML = activeContent;
						}
					}

					if (previewRef) {
						const nextContent = swiper.slides[nextIndex].innerHTML;
						previewRef.innerHTML = nextContent;
					}
				},
			},
		});
		return () => {
			swiperRef.current?.destroy();
		};
	}, []);

	return (
		<div className="front-page-swiper">
			<div className="swiper-wrapper">
				{data.map((post) => (
					<HeroPostSlide key={post.postObject.id} post={post} />
				))}
			</div>

			<div className="kkd-gradient-red-overlay"></div>
			<div className="kkd-gradient-dark-overlay"></div>

			<div className="swiper-content">
				<HeroPostContent post={data[0]} />
			</div>

			<div className="swiper-pagination"></div>

			{data.length > 1 && <HeroPostPreview swiperRef={swiperRef} post={data[1]} />}

			<div className="swiper-progress-bar">
				<div ref={progressStrokeRef} className="progress"></div>
			</div>
		</div>
	);
}
