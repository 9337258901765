export enum PeriodTitleMapping {
	first_period = 'first_period',
	second_period = 'second_period',
	third_period = 'third_period',
	fourth_period = 'fourth_period',
}

export const PeriodTitles = [
	PeriodTitleMapping.first_period,
	PeriodTitleMapping.second_period,
	PeriodTitleMapping.third_period,
	PeriodTitleMapping.fourth_period,
];
