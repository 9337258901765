import React from 'react';
import Link from 'next/link';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import nl from 'date-fns/locale/nl';
import Time from './time';
import Icon from './icon/Icon';

import { Table, TableBody, TableCell, TableRow } from './table';
import { IMatchRoundData } from '../models/matchRoundData';
import { MatchStatus } from '../models/match-status';
import { Badge } from './badge';

interface IProps {
	matchesData: IMatchRoundData;
	snug?: boolean;
	invertedHeaderColors?: boolean;
}

export default function RoundsTable({ matchesData, snug }: IProps) {
	return (
		<div className="overflow-x">
			<Table className="rounds-table table-auto overflow-x-scroll w-full text-white font-mono font-normal text-base text-center" snug={snug}>
				<TableBody>
					{matchesData?.matches?.map((match) => (
						<TableRow key={match.slug} className="match-row" striped={false}>
							<TableCell className="!pl-0 w-12">
								{match.channel &&
									<Link href={`/wedstrijden/${match.slug}`}>
										<img src={`/images/channel_${match.channel}.png`} alt='ESPN' />
									</Link>
								}
							</TableCell>
							<TableCell className="text-center font-bold">
								<Link href={`/wedstrijden/${match.slug}`}>
									{match.homeContestant.contestantCode}
								</Link>
							</TableCell>
							<TableCell className="text-center !p-0">
								{match.homeContestant?.contestantLogo &&
									<Link href={`/wedstrijden/${match.slug}`}>
										<img
											src={match.homeContestant.contestantLogo}
											alt={match.homeContestant.contestantClubName}
											className={snug ? 'inline-block w-10 h-10' : 'inline-block w-12 h-12'}
										/>
									</Link>
								}
							</TableCell>
							<TableCell className="text-center">
								{(match.status === MatchStatus.Playing || match.status === MatchStatus.Played) ? (
									<Link href={`/wedstrijden/${match.slug}`}>
										<Badge className="bg-[#24273D]">
											<span className="tracking-normal text-base uppercase font-mono font-bold">
												{match.scores?.home ?? 0}-{match.scores?.away ?? 0}
											</span>
										</Badge>
									</Link>
								) : (
									<Link href={`/wedstrijden/${match.slug}`}>
										<div className="flex flex-col justify-center items-center">
											<span className="block uppercase text-gray-500 text-xs mb-0 font-bold">
												<time>
													{format(parseISO(match.date), 'd MMM', { locale: nl })?.slice(0, -1)}
												</time>
											</span>
											<span className="block uppercase text-gray-500 text-base font-bold">
												<Time timeString={match.time} />
											</span>
										</div>
									</Link>
								)}
							</TableCell>
							<TableCell className="text-center !p-0">
								{match.awayContestant?.contestantLogo &&
									<Link href={`/wedstrijden/${match.slug}`}>
										<img
											src={match.awayContestant.contestantLogo}
											alt={match.awayContestant.contestantClubName}
											className={snug ? 'inline-block w-10 h-10' : 'inline-block w-12 h-12' }
										/>
									</Link>
								}
							</TableCell>
							<TableCell className="text-center font-bold">
								<Link href={`/wedstrijden/${match.slug}`}>
									{match.awayContestant.contestantCode}
								</Link>
							</TableCell>
							<TableCell className="text-center w-12">
								<div className="flex items-center justify-center">
									<Link href={`/wedstrijden/${match.slug}`} className="text-right inline-block">
										<Icon size={20} icon="chevron-right" color="#E2013D" />
									</Link>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	);
}
