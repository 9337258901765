import React from 'react';
import FlocklerEmbed from '@flockler/react-flockler-embed';
import config from '../lib/config';
import { H2 } from './text';

const SocialWall = () => (
	<div>
		<H2 className="mb-4 text-center lg:text-left">Social Media</H2>
		<FlocklerEmbed
			siteUuid={config.flocklerSiteId}
			embedUuid={config.flocklerEmbedId}
		/>
	</div>
);

export default SocialWall;
