import React from 'react';
import PostListItem from './post-list-item';

import { IPost } from '../models/post';

interface IProps {
	posts: IPost[];
	snug?: boolean;
}

export default function MoreStoriesList({ posts, snug = false }: IProps) {
	return (
		<section>
			<ul className="divide-y divide-gray-500">
				{posts.map(({ node }) => (
					<PostListItem
						key={node.slug}
						title={node.title}
						date={node.date}
						slug={node.slug}
						snug={snug}
					/>
				))}
			</ul>
		</section>
	);
}
