import React from 'react';
import HeroPostContent from './hero-post-content';
import { IHeroPost } from '../models/hero-slider';

interface IProps {
	post: IHeroPost;
}

export default function HeroPostSlide({ post }: IProps) {
	return (
		<div className="swiper-slide">
			<img
				src={post.postObject.featuredImage?.node.sourceUrl}
				alt={post.postObject.featuredImage?.node.altText}
			/>
			<div className="slide-content">
				<HeroPostContent post={post} />
			</div>
		</div>
	);
}
