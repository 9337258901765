import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import nl from 'date-fns/locale/nl';

interface IProps {
	dateString: string;
}

export default function Date({ dateString }: IProps) {
	const date = parseISO(dateString);

	return (
		<time dateTime={dateString}>
			{format(date, 'd MMM yyyy', { locale: nl })}
		</time>
	);
}
