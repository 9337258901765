import React from 'react';
import Date from './date';
import CoverImage, { ICoverImageprops } from './cover-image';
import Link from 'next/link';

import { Badge } from './badge';
import { H6, Muted } from './text';

interface IProps {
	title: string;
	date: string;
	slug: string;
	children?: React.ReactNode;
	className?: string;
	bgColor?: 'solid' | 'transparent';
	coverImage: ICoverImageprops['coverImage'];
	excerpt?: string;
	badge?: string;
}

export default function PostPreview({
	title,
	coverImage,
	date,
	excerpt,
	slug,
	bgColor = 'transparent',
	className,
	badge,
	...props
}: IProps) {
	let cardClassName = className || '';
	return (
		<div>
			<div
				className={`rounded-sm flex flex-col h-full mb-4 lg:mb-0 ${
					bgColor === 'transparent' ? 'bg-transparent' : 'bg-white'
				} ${cardClassName}`}
				{...props}
			>
				{coverImage && (
					<CoverImage
						title={title}
						coverImage={coverImage}
						slug={slug}
						className="aspect-video rounded-t-sm"
					/>
				)}
				<div className={bgColor === 'transparent' ? 'p-4 lg:px-2 lg:py-4' : 'p-4 lg:p-6'}>
					{badge && <Badge className="mb-3">{badge}</Badge>}
					<H6 className="mb-2">
						<Link
							href={`/nieuws/${slug}`}
							className="hover:underline"
							dangerouslySetInnerHTML={{ __html: title }}
						></Link>
					</H6>
					<Muted className="uppercase mb-1">
						<Date dateString={date} />
					</Muted>
					{excerpt &&
						<div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: excerpt }} />
					}
				</div>
			</div>
		</div>
	);
}
