import React from 'react';
import ContentCarousel from './carousel';
import PeriodStandingsTable from './period-standings-table';

import { divideArrays } from '../utils/divideArray';
import { useViewportWidth } from '../utils/useViewPortWidth';
import { IPeriodStanding } from '../models/period-standing';
import { PeriodTitleMapping, PeriodTitles } from '../models/period-title';

interface IProps {
	rankingsData: { [key in PeriodTitleMapping]: IPeriodStanding[] };
	inverted?: boolean;
}

interface IStandingsLayoutProps {
	standings: IPeriodStanding[];
	inverted?: boolean;
}

function StandingsLayout({ standings, inverted }: IStandingsLayoutProps) {
	const { width } = useViewportWidth();
	const [leftCol, rightCol] = divideArrays(standings);

	if (width < 1280) {
		return (
			<div className="block xl:flex">
				<div className="flex-1">
					<div className="py-2 align-middle px-0 xl:pr-8">
						<PeriodStandingsTable period={standings} snug invertedHeaderColors />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="block xl:flex">
			<div className="flex-1">
				<div className="py-2 align-middle px-0 xl:pr-8">
					<PeriodStandingsTable period={leftCol} snug invertedHeaderColors={inverted} />
				</div>
			</div>
			<div className="flex-1">
				<div className="py-2 align-middle px-0 xl:pl-8">
					<PeriodStandingsTable period={rightCol} snug invertedHeaderColors={inverted} secondColumn />
				</div>
			</div>
		</div>
	);
}

export default function PeriodeItem({ rankingsData, inverted }: IProps) {
	// Todo: kijken of er een goede manier is om te bepalen wanneer de volgende periode start.
	const periodTitleToIndex: { [key in PeriodTitleMapping]: number } = {
		[PeriodTitleMapping.first_period]: 0,
		[PeriodTitleMapping.second_period]: 1,
		[PeriodTitleMapping.third_period]: 2,
		[PeriodTitleMapping.fourth_period]: 3,
	};

	const carouselItems = [];

	for (let i = 0; i < PeriodTitles.length; i++) {
		const rankingsKey = PeriodTitles[i];
		const periodArray = rankingsData[rankingsKey];
		carouselItems.push(<StandingsLayout key={i} standings={periodArray} inverted={inverted} />);
	}

	return (
		<ContentCarousel
			items={carouselItems}
			inverted={inverted}
			startIndex={periodTitleToIndex[PeriodTitleMapping.first_period]}
		/>
	);
}
