import React from 'react';
import { classNames } from '../../utils/applyCssClassNames';

interface IProps {
	children?: React.ReactNode;
	className?: string;
	snug?: boolean
}

export function Table({children, className, snug = false}: IProps) {
	let tableClassName = className || '';

	return (
		<table className={classNames(
			snug
				? 'table-snug'
				: 'table-medium',
			`table min-w-full divide-y divide-gray-300 font-inter text-black-500 border-collapse border-spacing-0 ${tableClassName}`,
		)}>{children}</table>
	);
}

