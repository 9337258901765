import React from 'react';
import { parse, format } from 'date-fns';

interface Props {
  timeString: string;
}

const Time: React.FC<Props> = ({ timeString }) => {
  // Parse the timeString to create a Date object
  const dateObject = parse(timeString, 'HH:mm:ss', new Date());

  // Format the Date object to "HH:mm" format
  const formattedTime = format(dateObject, 'HH:mm');

  return <span>{formattedTime}</span>;
};

export default Time;
