import React from 'react';
import Link from 'next/link';

import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableHeadRow } from './table';
import { IPeriodStanding } from '../models/period-standing';

interface IProps {
	snug?: boolean;
	invertedHeaderColors?: boolean;
	period: IPeriodStanding[];
	secondColumn?: boolean;
}

export default function PeriodStandingsTable({ period, snug, invertedHeaderColors, secondColumn }: IProps) {
	return (
		<div className="overflow-x-auto">
			<Table className="table-auto overflow-scroll w-full" snug={snug}>
				<TableHead>
					<TableHeadRow className={invertedHeaderColors ? 'text-white' : 'text-black-500'}>
						<TableHeadCell className="text-center">#</TableHeadCell>
						<TableHeadCell className="text-center">Club</TableHeadCell>
						<TableHeadCell
							className={
								snug
									? 'font-bold hidden md:table-cell lg:table-cell min-[1280px]:hidden min-[1536px]:table-cell'
									: 'font-bold hidden lg:table-cell'
							}
						></TableHeadCell>
						<TableHeadCell>GS</TableHeadCell>
						<TableHeadCell>W/G/V</TableHeadCell>
						<TableHeadCell>P</TableHeadCell>
						<TableHeadCell>DV-DT</TableHeadCell>
						<TableHeadCell>DS</TableHeadCell>
					</TableHeadRow>
				</TableHead>
				<TableBody className="bg-white">
					{period?.map((club, i) => (
						<tr key={club.contestantId} className="even:bg-gray-50">
							<TableCell nowrap className="text-center">
								{secondColumn ? i + 10 + 1 : i + 1}
							</TableCell>
							<TableCell nowrap className="text-center w-10">
								{club.contestantLogo && (
									<Link href={`/teams/${club.contestantClubSlug}`}>
										<img
											src={club.contestantLogo}
											alt={club.contestantClubName}
											className={snug ? 'inline-block w-6 h-6' : 'inline-block w-10 h-10'}
										/>
									</Link>
								)}
							</TableCell>
							<TableCell
								nowrap
								className={
									snug
										? 'font-bold hidden md:table-cell lg:table-cell min-[1280px]:hidden min-[1536px]:table-cell'
										: 'font-bold hidden lg:table-cell'
								}
							>
								<Link href={`/teams/${club.contestantClubSlug}`}>{club.contestantClubName}</Link>
							</TableCell>
							<TableCell nowrap>{club.matchesPlayed}</TableCell>
							<TableCell nowrap>
								{club.matchesWon} / {club.matchesDrawn} / {club.matchesLost}
							</TableCell>
							<TableCell nowrap>{club.points}</TableCell>
							<TableCell nowrap>
								{club.goalsFor} / {club.goalsAgainst}
							</TableCell>
							<TableCell nowrap>{club.goalDifference}</TableCell>
						</tr>
					))}
				</TableBody>
			</Table>
		</div>
	);
}
