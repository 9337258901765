import React from 'react';

import { VideoCard } from './video-card';
import { IPost } from '../models/post';

interface IProps {
	posts: IPost[];
}

export default function MoreVideoPosts({ posts }: IProps) {
	return (
		<>
			{posts.map(({ node }) => (
				<VideoCard
					key={node.slug}
					size="small"
					bgColor="transparent"
					title={node.title}
					imageUrl={node.featuredImage.node.sourceUrl}
					alt={node.title}
					videoLink={`/video/${node.slug}`}
				/>
			))}
		</>
	);
}
