import React, { useEffect, useState } from 'react';
import { IconButton } from './icon-button';
import { H3 } from './text';
import { classNames } from '../utils/applyCssClassNames';
import { Transition } from '@headlessui/react';

interface IProps {
	items: React.ReactNode[];
	inverted?: boolean;
	startIndex?: number | undefined;
}

export default function ContentCarousel({ items, inverted = false, startIndex }: IProps) {
	const [currentIndex, setCurrentIndex] = useState<number>(startIndex ?? 0);

	useEffect(() => {
		setCurrentIndex(startIndex ?? 0);
	}, [startIndex]);

	const handlePrev = () => {
		setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : items.length - 1));
	};

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex < items.length - 1 ? prevIndex + 1 : 0));
	};

	const bgClassName = classNames(inverted ? 'bg-white' : '');

	return (
		<div className="mb-12">
			<>
				<div className="flex justify-between items-center mb-4">
					<H3 className={inverted ? 'text-white mb-1' : 'mb-1 text-black-500'}>
						{currentIndex + 1}e Periode
					</H3>
					<div>
						<IconButton
							className={`ml-4 ${bgClassName}`}
							transparent={inverted ? true : false}
							icon={'arrow-left'}
							onClick={handlePrev}
							disabled={currentIndex === 0}
						/>
						<IconButton
							className={`ml-4 ${bgClassName}`}
							transparent={inverted ? true : false}
							icon={'arrow-right'}
							onClick={handleNext}
							disabled={currentIndex === items.length - 1}
						/>
					</div>
				</div>

				<div>
					{items.map((item, index) => (
						<Transition
							key={index}
							as="div"
							show={index === currentIndex}
							enter="transition-opacity duration-500"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity duration-150"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							{item}
						</Transition>
					))}
				</div>
			</>
		</div>
	);
}
