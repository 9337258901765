import React, { useMemo } from 'react';
import StandingsTable, { PromotionTeamsType } from './standings-table';
import { IStandings } from '../models/standings';
import { divideArrays } from '../utils/divideArray';
import { useViewportWidth } from '../utils/useViewPortWidth';

interface IProps {
	clubs: IStandings[];
}

export function StandingsColumLayout({ clubs }: IProps) {
	const { width } = useViewportWidth();
	const [standingsLeftCol, standingsRightCol] = divideArrays(clubs);

	// filter out the Jong teams and assign promotion status to the first 2 teams
	const promotionTeams = useMemo(
		() =>
			clubs
				.filter((club) => !club.contestantName.includes('Jong'))
				.slice(0, 8)
				.reduce((acc, club, i) => ({ ...acc, [club.contestantId]: i < 2 }), {} as PromotionTeamsType),
		[clubs],
	);

	// assign colors to the clubs based on their promotion status
	const assignColorsToClubs = (clubsArray: IStandings[]): IStandings[] => {
		return clubsArray.map((club) => {
			let rankStatus = '';

			if (promotionTeams.hasOwnProperty(club.contestantId)) {
				rankStatus = promotionTeams[club.contestantId] ? 'green' : 'red';
			}

			return {
				...club,
				rankStatus,
			};
		});
	};

	const coloredStandingsLeftCol = useMemo(
		() => assignColorsToClubs(standingsLeftCol),
		[standingsLeftCol, promotionTeams],
	);
	const coloredStandingsRightCol = useMemo(
		() => assignColorsToClubs(standingsRightCol),
		[standingsRightCol, promotionTeams],
	);

	if (width < 1280) {
		return (
			<div className="block xl:flex">
				<div className="flex-1">
					<div className="py-2 align-middle px-0 xl:pr-8">
						<StandingsTable clubs={clubs} snug invertedHeaderColors legend displayPeriodTitle />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="block xl:flex">
			<div className="flex-1">
				<div className="py-2 align-middle px-0 xl:pr-8">
					<StandingsTable clubs={coloredStandingsLeftCol} snug invertedHeaderColors displayPeriodTitle />
				</div>
			</div>
			<div className="flex-1">
				<div className="py-2 align-middle px-0 xl:pl-8">
					<StandingsTable clubs={coloredStandingsRightCol} snug invertedHeaderColors startIndex={10} />
				</div>
			</div>
		</div>
	);
}
