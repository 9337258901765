import React from 'react';
import PostPreview from './post-preview';

import { IPost } from '../models/post';

interface IProps {
	posts: IPost[];
}

export default function MoreStories({ posts }: IProps) {
	return (
		<section>
			<div className="grid grid-cols-1 md:grid-cols-2 md:gap-6">
				{posts.map(({ node }) => (
					<PostPreview
						key={node.slug}
						title={node.title}
						coverImage={node.featuredImage}
						date={node.date}
						slug={node.slug}
						excerpt={node.excerpt}
						badge={node?.tags?.nodes[0]?.name}
					/>
				))}
			</div>
		</section>
	);
}
