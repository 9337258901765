import React from 'react';
import Icon, { Icons } from './icon/Icon';
import { classNames } from '../utils/applyCssClassNames';

interface IProps {
	onClick?: () => void;
	icon: Icons;
	transparent?: boolean;
	// Todo: DEVP-140, Hex code for now, but we can make this a type later
	className?: string;
	disabled?: boolean;
}

export function IconButton({ icon, transparent, className, disabled, ...props }: IProps) {
	const iconColor = transparent ? '#E2013D' : '#ffffff';

	const bgColor = classNames(
		'transition-opacity hover:opacity-90',
		transparent ? 'bg-transparent text-red-500 hover:bg-white focus:ring-slate-500 focus:ring-offset-slate-600 disabled:bg-white' : 'bg-red-500 hover:bg-red-600 text-white focus:ring-red-600 focus:ring-offset-red-500',
	)

	return (
		<button
			type="button"
			className={classNames(
				bgColor,
				`font-bold py-3 px-4 rounded-md border-0 outline-none focus:ring-1 focus:ring-red-600 focus:ring-offset-1 disabled:opacity-60 disabled:pointer-events-none ${className}`,
			)}
			disabled={disabled}
			{...props}
		>
			<Icon size={18} icon={icon} color={iconColor} />
		</button>
	);
}
