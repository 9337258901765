import React, { Fragment, useState } from 'react';
import { Tab, Transition } from '@headlessui/react';
import { classNames } from '../utils/applyCssClassNames';

interface TabItem {
	label: string;
	children: React.ReactNode;
}

interface IProps {
	size?: 'sm' | 'md' | 'lg';
	tabColorInterted?: boolean;
	tabs: TabItem[];
	selectedColor?: 'white' | 'red-500';
}

export default function Tabs({ tabs, size = 'lg', tabColorInterted, selectedColor = 'white' }: IProps) {
	const textColor = classNames(tabColorInterted ? 'text-white opacity-100' : 'text-black-500 opacity-500');

	const className = classNames(
		`border-b border-${selectedColor} justify-center`,
		tabs.length > 2 ? 'block lg:flex' : 'flex',
	);

	return (
		<section className="w-full h-auto">
			<Tab.Group>
				{({ selectedIndex }) => (
					<>
						<Tab.List className={className}>
							{tabs.map((tab, index) => (
								<Tab
									key={index}
									className={({ selected }) =>
										classNames(
											`tab-${size} w-full lg:w-auto`,
											'ring-0 focus:ring-0 focus:ring-offset-0" focus:outline-none',
											selected
												? `${textColor} border-${selectedColor} border-b-4 hover:opacity-80`
												: 'text-black-500 opacity-50  hover:opacity-100',
										)
									}
								>
									{tab.label}
								</Tab>
							))}
						</Tab.List>
						<Tab.Panels className="mt-4 lg:mt-8">
							{tabs.map((tab, index) => (
								<Tab.Panel key={index} as={Fragment} static={true}>
									<Transition
										as="article"
										show={selectedIndex === index}
										enter="transition-opacity duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="transition-opacity duration-150"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
										// className={classNames('p-2 lg:p-0')}
									>
										{tab.children}
									</Transition>
								</Tab.Panel>
							))}
						</Tab.Panels>
					</>
				)}
			</Tab.Group>
		</section>
	);
}
