import React from 'react';

interface IProps {
	children?: React.ReactNode;
	className?: string;
}

export function TableHead({children, className}: IProps) {
	let classNames = className || '';

	return (
		<thead className={` ${classNames}`}>{children}</thead>
	);
}

