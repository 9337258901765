import React from 'react';
import Link from 'next/link';
import Icon from './icon/Icon';
import { IHeroPost } from '../models/hero-slider';

interface IProps {
	post: IHeroPost;
}

export default function HeroPostContent({ post }: IProps) {
	const data = post.postObject;
	return (
		<>
			<div className="flex flex-col gap-2 lg:gap-4 top-container">
				<div className="text-white theinhardt category">Laatste nieuws</div>
				<div className="w-20 h-px bg-kkd-red" />
			</div>
			<div className="text-white theinhardt title">{data.title}</div>
			<Link href={`/${data.slug}`} className="to-action" aria-label="Naar het artikel">
				<div className="text-kkd-red theinhardt pointer-events-auto font-semibold tracking-wider flex items-center">
					<span className="inline-block mr-3 hover:underline">Naar het artikel</span>
					<Icon icon={'arrow-right'} color="#E2013D" size={24} />
				</div>
			</Link>
		</>
	);
}
